@import '../../../../scss/vendors/include-media';
@import '../../../../scss/base/variables';



.ant-form {
  &.ant-form-vertical {
    &.settings-form {
        // max-width: 700px;
        // margin-bottom: 170px;

        .bg-image-field {
          min-height: 180px;
          display: flex;
          align-items: center;

          @include media('>=tablet') {
            min-height: 300px;
          }
        }
    }
  }
}