@import '../../../scss/vendors/include-media';
@import '../../../scss/base/variables';


.interface-block {
  padding: 0 150px 0 0;
}

.upload-file-list-hidden {

  .ant-upload-list {
    display: none !important;
  }
}

.download-block {

  .download-box {
    display: flex;
    align-items: center;
    z-index: 1;

    @include media('>=1200px') {
      position: absolute;
      right: 10px;
      top: 7px;
    }

    .text {
      display: flex;
      align-items: center;
      margin-right: 5px;
    }
  }
}