.row-grid {
  width: 100%;
  display: grid;
  grid-gap: 30px;

  &.grid-auto-width {
    width: auto;
  }

  &.md {
    display: block !important;

    @include media('>=tablet') {
      display: grid !important;
    }
  }

  &.grid-gap-20 {
    grid-gap: 20px;
  }

  &.grid-gap-10 {
    grid-gap: 10px;
  }

  &.no-gap {
    grid-gap: 0;
  }

  &.row-gap-0 {
    row-gap: 0;
  }

  &.column-gap-0 {
    column-gap: 0;
  }

  &.col-auto-100 {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }

  &.col-auto-200 {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  &.col-auto-300 {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  &.col-auto-400 {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }

  &.col-auto-500 {
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  }

  &.col-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  &.col-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &.col-4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &.col-5 {
    grid-template-columns: repeat(5, 1fr);
  }

  &.col-md-2 {
    @include media('>=tablet') {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &.col-md-3 {
    @include media('>=tablet') {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &.col-md-4 {
    @include media('>=tablet') {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &.col-md-5 {
    @include media('>=tablet') {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &.col-lg-2 {
    @include media('>=desktop') {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &.col-lg-3 {
    @include media('>=desktop') {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &.col-lg-4 {
    @include media('>=desktop') {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &.col-lg-5 {
    @include media('>=desktop') {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}

.d-flex {
  width: 100%;
  display: flex;

  &.flex-auto-width {
    width: auto;
  }
}


.d-flex-sm {
  width: 100%;

  @include media('>=phone') {
    display: flex;
  }
}

.d-flex-md {
  width: 100%;

  @include media('>=tablet') {
    display: flex;
  }
}

.d-flex-lg {

  @include media('>=desktop') {
    display: flex;
  }
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}


.h-100 {
  height: 100% !important;
}

.h-100vh {
  height: 100vh !important;
}

.h-100px {
  height: 100px !important;
}

.h-200px {
  height: 200px !important;
}

.h-300px {
  height: 300px !important;
}


.w-100 {
  width: 100% !important;
}

.w-100px {
  width: 100px !important;
}

.w-200px {
  width: 200px !important;
}

.w-300px {
  width: 300px !important;
}